<template>
  <el-container class="layout">
    <!-- 头部 -->
    <el-header>
      <nav-header />
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside v-if="!$route.meta.sidebarHide" style="width: 200px;">
        <sidebar />
      </el-aside>
      <!-- 主入口 -->
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
  </el-container>
</template>

<script>
import NavHeader from '@/components/navHeader'
import Sidebar from '@/components/sidebar'
export default {
  name: 'Layout',
  components: {
    'nav-header': NavHeader,
    'sidebar': Sidebar
  }
}
</script>

<style lang="less" scoped>
  .layout {
    height: 100%;
  }
</style>
