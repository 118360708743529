import axios from 'axios'
import { Message, Loading } from 'element-ui'
import router from '../router'
const loading = Loading.service
let loadingObj = null

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// request 拦截
service.interceptors.request.use(config => {
  // do something
  loadingObj = loading({
    fullscreen: true
  })
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, err => {
  console.log(err)
  return Promise.reject(err)
})

// response 拦截
service.interceptors.response.use(response => {
  let res = response.data
  loadingObj.close()
  if (res.code == 401) {
    localStorage.removeItem("token");
    router.replace({ path: "/login" })
  }
  if (res.code < 0) {
    Message.error(res.msg)
    return Promise.reject(res)
  }
  return res
}, err => {
  loadingObj.close()
  Message.error(err.message)
  return Promise.reject(err)
})

export default service
