<template>
  <div class="sidebar" >
    <el-menu :router="true" :default-active="activeIndex" class="el-menu-vertical-demo" ref="menu">
      <el-submenu :index="item.url"  v-for="(item, index) in sidebar" :key="index" v-if="item.children.length > 0">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{item.name}}</span>
        </template>
        <el-menu-item-group v-for="(items, index) in item.children" :key="index">
          <el-menu-item :index="items.url">{{items.name}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item :index="item.url" v-else>
        <i :class="item.icon"></i>
        <span>{{item.name}}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { menuRequest,getMenuvpowerRequest } from "@/api/home";
import { async } from "q";
export default {
  name: "Sidebar",
  computed: {
    activeIndex() {
      return this.$route.path;
    }
  },
  data() {
    return {
      sidebar: []
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    async getMenu() {
      await getMenuvpowerRequest().then(res => {
        this.sidebar = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.sidebar,
.el-menu {
  height: 100%;
}
</style>
