import request from '@/utils/request'

// 系统首页
export function menuRequest(params) {
  return request({
    url: '/admin/home/getMenu',
    method: 'get',
    params
  })
}
export function getMenuvpowerRequest(params) {
  return request({
    url: '/admin/home/getMenuvpower',
    method: 'get',
    params
  })
}


// 获取详情信息
export function getdatilsetupRequest(params) {
  return request({
    url: '/admin/home/getdatilsetup',
    method: 'get',
    params
  })
}

// 添加信息
export function setdatilsetupRequest(data) {
  return request({
    url: '/admin/home/setdatilsetup',
    method: 'post',
    data
  })
}


// 基础数据
export function gethomeStatisticsRequest(data) {
  return request({
    url: '/admin/home/gethomeStatistics',
    method: 'get',
    data
  })
}
// 基础数据
export function getStatisticsDayRequest(params) {
  return request({
    url: '/admin/home/getStatisticsDay',
    method: 'get',
    params
  })
}
// 基础数据
export function getStatisticsproductRequest(params) {
  return request({
    url: '/admin/home/getStatisticsproduct',
    method: 'get',
    params
  })
}
// 基础数据
export function getPCTreeList(params) {
  return request({
    url: '/admin/home/getPCTreeList',
    method: 'get',
    params
  })
}


