import request from '@/utils/request'

// 管理员登入
export function loginRequest(params) {
  return request({
    url: '/amin/SysLogin/Login',
    method: 'get',
    params
  })
}

// 修改密码
export function updatepwdRequest(params) {
  return request({
    url: '/admin/home/updatepwd',
    method: 'get',
    params
  })
}
