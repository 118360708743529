import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const whiteList = ['Login']

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    // 登录
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  },
  {
    // 首页
    path: '/home',
    name: 'Home',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'Homeindex',
        component: () => import('@/views/home/index')
      }, {
        path: 'setup',
        name: 'homesetup',
        component: () => import('@/views/home/setup')
      }, {
        path: 'pointsetup',
        name: 'homepointsetup',
        component: () => import('@/views/home/pointsetup')
      }, {
        path: 'about',
        name: 'homeabout',
        component: () => import('@/views/home/about')
      }, {
        path: 'saleStatistics',
        name: 'homesaleStatistics',
        component: () => import('@/views/home/saleStatistics')
      }, {
        path: 'returnaddress',
        name: 'homereturnaddress',
        component: () => import('@/views/home/returnaddress')
      }, {
        path: 'saledetail',
        name: 'homesaledetail',
        component: () => import('@/views/home/saledetail')
      }, {
        path: 'salemoneydetail',
        name: 'salemoneydetail2',
        component: () => import('@/views/home/salemoneydetail')
      }, {
        path: 'areadetail',
        name: 'areadetail2',
        component: () => import('@/views/home/areadetail')
      }, {
        path: 'giftdetail',
        name: 'giftdetail2',
        component: () => import('@/views/home/giftdetail')
      },



    ]
  }, {
    // 系统菜单
    path: '/sys_menu',
    name: 'sys_menu',
    component: Layout,
    meta: {
      title: '系统菜单',
      icon: 'el-icon-location'
    },
    children: [
      {
        path: 'list',
        name: 'sys_menulist',
        component: () => import('@/views/sys_menu/list')
      },


    ]
  }, ,
  {
    // 系统用户
    path: '/systemset',
    name: 'Systemset',
    component: Layout,
    children: [
      {
        path: 'oplist',
        name: 'oplist',
        component: () => import('@/views/opinion/list')
      },
    ]
  }, {
    // 管理员账户
    path: '/admin',
    name: 'admin',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'adminlist',
        component: () => import('@/views/admin/list')
      }, {
        path: 'edit',
        name: 'admindit',
        component: () => import('@/views/admin/edit')
      }

    ]
  }, {
    // 广告管理
    path: '/adv',
    name: 'adv',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'advlist',
        component: () => import('@/views/adv/list')
      }, {
        path: 'edit',
        name: 'advedit',
        component: () => import('@/views/adv/edit')
      }

    ]
  },
  {
    path: '/cmsmange',
    name: 'Cmsmange',
    component: Layout,
    children: [
      // 文章分类
      {
        path: 'ArticleType',
        name: 'Articletype',
        component: () => import('@/views/articleType/list')
      },
      {
        path: 'ArticleType/create',
        name: 'Articletypecreate',
        component: () => import('@/views/articleType/create')
      },
      {
        path: 'ArticleType/edit/:id',
        name: 'Articletypeedit',
        component: () => import('@/views/articleType/edit')
      },
      // 文章列表
      {
        path: 'ArticleList',
        name: 'Articlelist',
        component: () => import('@/views/articleList/list')
      },
      {
        path: 'ArticleList/create',
        name: 'Articlelistcreate',
        component: () => import('@/views/articleList/create')
      },
      {
        path: 'ArticleList/edit/:id',
        name: 'Articlelistedit',
        component: () => import('@/views/articleList/edit')
      },
    ]
  },
  {
    // 会员管理
    path: '/wx',
    name: 'Wx',
    component: Layout,
    children: [
      {
        path: 'user/list',
        name: 'Userlist',
        component: () => import('@/views/user/list')
      },
      {
        path: 'user/integral',
        name: 'Integral',
        component: () => import('@/views/user/integral')
      },
      {
        path: 'user/usercoupon',
        name: 'usercoupon',
        component: () => import('@/views/user/usercoupon')
      },


      {
        path: 'user/balance',
        name: 'Balance',
        component: () => import('@/views/user/balance')
      },
      {
        path: 'user/edit/:id',
        name: 'Useredit',
        component: () => import('@/views/user/edit')
      },

    ]
  },
  {
    // 商品类型
    path: '/zrproducttype',
    name: 'zrproducttype',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zrproducttypelist',
        component: () => import('@/views/zrproducttype/list')
      }, {
        path: 'edit',
        name: 'zrproducttypeedit',
        component: () => import('@/views/zrproducttype/edit')
      }

    ]
  }, {
    // 会员等级
    path: '/usergrade',
    name: 'usergrade',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'usergradelist',
        component: () => import('@/views/usergrade/list')
      }, {
        path: 'edit',
        name: 'usergradeedit',
        component: () => import('@/views/usergrade/edit')
      }

    ]
  }, {
    // 会员礼包
    path: '/zr_giftbag',
    name: 'zr_giftbag',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zr_giftbaglist',
        component: () => import('@/views/zr_giftbag/list')
      }, {
        path: 'edit',
        name: 'zr_giftbagedit',
        component: () => import('@/views/zr_giftbag/edit')
      }

    ]
  }, {
    // 推广码管理
    path: '/wx_userpushcode',
    name: 'wx_userpushcode',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'wx_userpushcodelist',
        component: () => import('@/views/wx_userpushcode/list')
      }, {
        path: 'edit',
        name: 'wx_userpushcodeedit',
        component: () => import('@/views/wx_userpushcode/edit')
      }

    ]
  },



  {
    // 商品管理
    path: '/zrproduct',
    name: 'zrproduct',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zrproductlist',
        component: () => import('@/views/zrproduct/list')
      }, {
        path: 'edit',
        name: 'zrproductedit',
        component: () => import('@/views/zrproduct/edit')
      }

    ]
  }, {
    // 商品管理
    path: '/zrproductintegral',
    name: 'zrproductintegral',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zrproductintegrallist',
        component: () => import('@/views/zrproductintegral/list')
      }, {
        path: 'edit',
        name: 'zrproductintegraledit',
        component: () => import('@/views/zrproductintegral/edit')
      }

    ]
  }, {
    // 商品管理
    path: '/sys_role',
    name: 'sys_role',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'sys_rolelist',
        component: () => import('@/views/sys_role/list')
      }, {
        path: 'edit',
        name: 'sys_roleedit',
        component: () => import('@/views/sys_role/edit')
      }, {
        path: 'rolepower',
        name: 'sys_rolerolepower',
        component: () => import('@/views/sys_role/rolepower')
      }


    ]
  }, {
    // 商家管理
    path: '/zrshop',
    name: 'zrshop',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zrshoplist',
        component: () => import('@/views/zrshop/list')
      }, {
        path: 'edit',
        name: 'zrshopedit',
        component: () => import('@/views/zrshop/edit')
      }

    ]
  }, {
    // 员工管理
    path: '/zrshopstaff',
    name: 'zrshopstaff',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zrshopstafflist',
        component: () => import('@/views/zrshopstaff/list')
      }, {
        path: 'edit',
        name: 'zrshopstaffedit',
        component: () => import('@/views/zrshopstaff/edit')
      }

    ]
  }, {
    // 优惠券
    path: '/fj_coupon',
    name: 'fj_coupon',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'fj_couponlist',
        component: () => import('@/views/fj_coupon/list')
      }, {
        path: 'edit',
        name: 'fj_couponedit',
        component: () => import('@/views/fj_coupon/edit')
      }

    ]
  }, {
    // 优惠券领取
    path: '/fj_couponuser',
    name: 'fj_couponuser',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'fj_couponuserlist',
        component: () => import('@/views/fj_couponuser/list')
      }, {
        path: 'edit',
        name: 'fj_couponuseredit',
        component: () => import('@/views/fj_couponuser/edit')
      }

    ]
  }, {
    // 订单
    path: '/fj_order',
    name: 'fj_order',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'fj_orderlist',
        component: () => import('@/views/fj_order/list')
      }, {
        path: 'deyexpress',
        name: 'fj_orderdeyexpress',
        component: () => import('@/views/fj_order/deyexpress')
      }, {
        path: 'edit',
        name: 'fj_orderedit',
        component: () => import('@/views/fj_order/edit')
      }

    ]
  }, {
    // 评论
    path: '/zr_productcomment',
    name: 'zr_productcomment',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zr_productcommentlist',
        component: () => import('@/views/zr_productcomment/list')
      }, {
        path: 'edit',
        name: 'zr_productcommentedit',
        component: () => import('@/views/zr_productcomment/edit')
      }, {
        path: 'addbatch',
        name: 'zr_productcommentaddbatch',
        component: () => import('@/views/zr_productcomment/addbatch')
      }

    ]
  }, {
    // 购买弹框
    path: '/zr_productbuytips',
    name: 'zr_productbuytips',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zr_productbuytipslist',
        component: () => import('@/views/zr_productbuytips/list')
      }, {
        path: 'edit',
        name: 'zr_productbuytipsedit',
        component: () => import('@/views/zr_productbuytips/edit')
      }

    ]
  }, {
    // 礼包订单
    path: '/fj_orderbag',
    name: 'fj_orderbag',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'fj_orderbaglist',
        component: () => import('@/views/fj_orderbag/list')
      }

    ]
  }, {
    // 满送配置
    path: '/fulldelivery',
    name: 'zr_productHDMS',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'zr_productHDMSlist',
        component: () => import('@/views/zr_productHDMS/list')
      }, {
        path: 'edit',
        name: 'zr_productHDMSedit',
        component: () => import('@/views/zr_productHDMS/edit')
      }

    ]
  },
  {
    // 系统日志
    path: '/syslog',
    name: 'syslog',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'sysloglist',
        component: () => import('@/views/syslog/list')
      },


    ]
  },
]

const router = new VueRouter({
  routes,
  // mode: 'history',
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.hidden) {
    alert("开发中");
    return next(false);
  }
  const token = localStorage.getItem('token')
  // 如果有已登录
  if (token) {
    const playload = token.split('.')[1]
    const playloadObj = JSON.parse(atob(playload))
    const tokenExpDate = Number(playloadObj.exp + '000')
    if (new Date().getTime() > tokenExpDate) {
      Message.error('登录信息已过期 请重新登录')
      localStorage.removeItem('token')
      next({
        name: 'Login'
      })
      return
    }
    // 如果去登录页面 重定向去‘/’
    else if (to.name === 'Login') {
      next('/')
      return
    }
    // 正常加载页面
    next()
  } else {
    // 如果访问的页面名不在白名单内 跳转去登录
    if (!whiteList.includes(to.name)) {
      next({
        name: 'Login'
      })
      return
    }
    // 正常加载页面
    next();
  }
})

export default router
