import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/global.less'

Vue.use(ElementUI);

Vue.config.productionTip = false

// 表单验证
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// 注册全局组件
import { Message, Loading } from 'element-ui'
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service

//引入css
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_editor.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/languages/zh_cn";
//导入依赖
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
