<template>
  <div>
    <div class="nav-header">
      <div class="float-left">
        <a href="javascript:void(0)" @click="home">雪芭比后台管理系统</a>
      </div>
      <div class="float-right">
        <p>
          <!-- <el-dropdown trigger="click" @command="handleCommand"> -->
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{username}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="pwdedit">修改密码</el-dropdown-item>
              <el-dropdown-item divided command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </p>
      </div>
    </div>
    <el-dialog title="修改密碼" :visible.sync="passwordVisible">
      <el-form>
        <el-form-item label="原密碼" :error="$v.oldpassword.$error ? '请输入原密碼!' : ''">
          <el-input v-model="oldpassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密碼" :error="$v.newpassword.$error ? '请输入新密碼!' : ''">
          <el-input v-model="newpassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePwd">確 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updatepwdRequest } from "@/api/user";
import { required } from "vuelidate/lib/validators";
export default {
  name: "NavHeader",
  data() {
    return {
      username: "",
      oldpassword: "",
      newpassword: "",
      passwordVisible: false
    };
  },
  created() {
    this.getUsername();
  },
  methods: {
    handleCommand(command) {
      if ("logout" == command) {
        this.$confirm("确认退出登录吗？", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          localStorage.setItem("token", "");
          this.$message.success("退出登录成功！");
          this.$router.push("/login");
        });
      } else if ("pwdedit" == command) {
        this.passwordVisible = true;
      }
    },
    getUsername() {
      const token = localStorage.getItem("token");
      const playload = token.split(".")[1];
      const playloadObj = JSON.parse(atob(playload));
      this.username = playloadObj.userName;
    },
    updatePwd() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        updatepwdRequest({
          oldpwd: this.oldpassword,
          newpwd: this.newpassword,
          newrepeatpwd: this.newpassword
        }).then(res => {
          if (res.code == 1) {
            this.$message.success("修改密碼成功，請重新登錄！");
            setTimeout(() => {
              localStorage.removeItem("token");
              this.$router.push({
                path: "/Login"
              });
            }, 500);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    home(){
      this.$router.push({
        name: 'Homeindex'
      })
    }
  },
  validations: {
    oldpassword: {
      required
    },
    newpassword: {
      required
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/vars.less";

.nav-header {
  background-color: @primary;
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .float-left {
    float: left;
    font-weight: bolder;
    padding: 0 10px;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .float-right {
    padding-right: 20px;

    .el-dropdown-link {
      color: white;
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }
}
</style>
